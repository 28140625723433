<template>
  <div class="payment-result">
    <Header/>
    <img
      alt=""
      :src="this.paymentResult[this.paymentStatus].image"
      class="payment-result-img"
    />
    <div class="payment-result-tip">
      {{ $t(this.paymentResult[this.paymentStatus].text) }}
    </div>
    <router-link
      :to="`${'/' + this.GLOBAL.currentLanguage + '/login'}`"
      v-show="this.paymentStatus === 'success'"
    >
      <button
        type="button"
        class="
          payment-result-btn
          btn-size-format-1
          btn-radius-format-1
          btn-border-format-1
          btn-color-5
          btn-cursor
        "
      >
        <span>{{ $t("back_to_login") }}</span>
      </button>
    </router-link>
    <router-link
      :to="`${'/' + this.GLOBAL.currentLanguage + '/pricing'}`"
      v-show="this.paymentStatus === 'fail'"
    >
      <button
        type="button"
        class="
          payment-result-btn
          btn-size-format-1
          btn-radius-format-1
          btn-border-format-1
          btn-color-5
          btn-cursor
        "
      >
        <span>{{ $t("back_to_pricing") }}</span>
      </button>
    </router-link>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  name: "PaymentResult",
  components: {Header, Footer},
  data() {
    return {
      paymentResult: {
        success: {
          image: require("../assets/image/payment_success.png"),
          text: "payment_successful",
        },
        fail: {
          image: require("../assets/image/payment_fail.png"),
          text: "payment_fail",
        },
      },
      paymentStatus: "success",
    };
  },
  methods: {},
  created() {
    this.paymentStatus =
      this.$route.query.status === undefined
        ? "success"
        : this.$route.query.status;
  },
};
</script>

<style scoped>
.payment-result {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-result-img {
  max-width: 100%;
  height: auto;
  margin-top: 59px;
}

.payment-result-tip {
  margin-top: 18px;
  font-size: 32px;
  font-weight: 500;
  line-height: 39px;
  color: #1d1d1d;
  text-align: center;
}

.payment-result-btn {
  margin-top: 34px;
  margin-bottom: 50px;
  width: 200px;
}

.payment-result-btn span {
  font-weight: 400;
  color: #1d1d1d;
}
</style>
